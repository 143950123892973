/* HomePage.css */

.homepage {
    display: flex;             /* Add flex display */
    flex-direction: column;    /* Flex direction as column */
    min-height: 100vh;
    background-image: url('certification.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    background-color: rgba(255, 255, 255, 0.6);
}
.homepage .nav-item {
    display: inline-block;
}

.homepage .nav-item:last-child {
    margin-right: 0;
}

.homepage .btn-link {
    color: #000;
    padding: 3px 8px;
    font-size: 0.9em;
    margin-right: 10px;
    font-weight: normal;
    border: none;
    background-color: transparent;
}

.nav-btn {
    padding: 0.25rem 0.75rem;
    margin-left: 0.5rem;
}

.homepage .nav-item .btn-link {
    padding: 3px 8px; /* This reduces the padding of the buttons */
    font-size: 0.9em; /* This reduces the font size a bit */
    margin-right: 10px; /* Maintain some spacing between buttons */
}

.homepage h1 {
    font-weight: normal;
    letter-spacing: -0.5px;
    color: #333;
}

.homepage .text-center {
    flex: 1;
    text-align: center;
    margin-top: 5rem;
}

.homepage .btn {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 15px 30px;
    border-radius: 25px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.homepage .btn:hover {
    background-color: #444;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(data:image/svg+xml,%3csvg xmlns='https://www.w3.org/2000/svg' width='30' height='30' fill='%23000'%3e%3cpath d='M1.5 0h27c.8 0 1.5.7 1.5 1.5S29.3 3 28.5 3h-27C.7 3 0 2.3 0 1.5S.7 0 1.5 0zm0 10h27c.8 0 1.5.7 1.5 1.5S29.3 13 28.5 13h-27c-.8 0-1.5-.7-1.5-1.5S.7 10 1.5 10zM28.5 20h-27c-.8 0-1.5-.7-1.5-1.5S.7 17 1.5 17h27c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zm0 10h-27c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5h27c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z'%3e%3c/path%3e%3c/svg%3e);
}

.mobile-auth-container {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
}

.mobile-auth-container .auth-buttons {
  flex-direction: row;
}

.mobile-auth-container .nav-btn {
  margin-left: 0.5rem;
  display: inline-block;
  width: auto;
}

.mobile-auth-container .welcome-text {
  margin-right: 20px;
  margin-bottom: 0;
}

/* Mobile Styles */
@media (max-width: 768px) { /* This breakpoint is for tablets and below. Adjust if needed. */
  @media (max-width: 768px) {
    .navbar-collapse.show {
      justify-content: flex-end;
    }
  }

  .mobile-auth-container {
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }

  .mobile-auth-container .auth-buttons {
    flex-direction: column;
  }

  .mobile-auth-container .nav-btn {
    margin: 5px 0;
    display: block;
    width: 100%;
  }

  .mobile-auth-container .welcome-text {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.homepage .navbar {
    background-color: #fff;  /* to ensure the navbar's background remains consistent */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* added for aesthetics */
    height: 60px;  /* adjust this value if necessary */
    align-items: center;
    display: flex;
    flex-direction: row; /* Ensure navbar uses row direction */
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.homepage .navbar-brand {
    font-size: 1.5em;
    white-space: nowrap;  /* This prevents the brand text from wrapping */
    flex-shrink: 0;       /* This ensures that the brand doesn't shrink if there's not enough space */
}


.navbar-collapse {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.auth-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto; /* this will push the auth-buttons to the right */
}

.homepage .nav-link {
    color: #000;
    transition: color 0.3s ease;
}

.homepage .nav-link:hover {
    color: #777;
}

.welcome-text {
  margin-right: 20px;  /* or whatever space you want */
  font-size: 16px;
  line-height: 24px;
  color: #333; /* or any other color you want */
}

.welcome-text a {
  color: #007bff; /* default bootstrap link color, adjust if needed */
  text-decoration: none;
}

.welcome-text a:hover {
  text-decoration: underline;
}

footer {
    display: flex;
    justify-content: space-between;  /* Change flex-start to space-between */
    padding: 1rem;
    background-color: #fff;
    border-top: 1px solid #eee;
    width: 100%;
    box-sizing: border-box;
}

footer iframe {
    margin-right: 15px; /* Add spacing between the iframe and subsequent links */
}

.footer-links-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px; /* spacing between links */
}

body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

footer a {
  margin-left: 1rem;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
}

footer a:hover {
  color: #777;
}

.youtube-video-container {
    margin-top: 30px;
    margin-bottom: 30px;
    position: relative;
    width: 50%;  /* adjust the width to 50% */
    padding-bottom: 28.125%;  /* 16:9 aspect ratio, reduced to half */
    overflow: hidden;
    margin-left: auto;  /* these two properties center the video container */
    margin-right: auto;
}

.youtube-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

@media (max-width: 768px) {
    .youtube-video-container {
        margin-top: 20px;
        width: 100%;  /* On mobile, let it take up the full width */
        padding-bottom: 56.25%;  /* adjust back to 16:9 on mobile */
    }
}
