.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Vertically centers if the container has a fixed height or in a flex parent */
    padding: 5% 0;
    background-color: #F8F8F8;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    height: 100vh; /* This will make sure your form is vertically centered */
}

.formParentContainer {
    display: flex;
    justify-content: center; /* Center child horizontally */
    align-items: center;     /* Center child vertically */
    width: 100%;             /* Take full viewport width */
    height: 100vh;           /* Take full viewport height */
}

/* Form styling */
.formContainer {
    margin: auto;
    width: 80%;
    max-width: 600px;    /* Reduced from 800px for sleeker look */
    background-color: #ffffff;
    padding: 40px 25px;  /* Reduced horizontal padding */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    text-align: center;
}

.formHeading {
    font-size: 32px;     /* Larger font size */
    color: #333;        /* Slightly darker than default black */
    margin-bottom: 16px; /* Spacing from the next element */
    font-weight: 600;   /* Semi-bold weight for prominence */
}

.formSubtext {
    font-size: 16px;
    color: #777;        /* Slightly light gray for subtlety */
    margin-bottom: 32px; /* More spacing before the form starts */
    line-height: 1.4;   /* Improved readability */
    max-width: 500px;   /* Constrain the width for aesthetic appeal */
    margin: 0 auto 32px;/* Auto margin for centering and spacing */
}


.label, .inputField, .textArea, .submitButton {
    display: block;
    width: 100%;
    margin: 15px 0;      /* Adjusted margin for better spacing */
}

.inputField, .textArea {
    padding: 10px 12px;  /* Slight reduction in padding */
    font-size: 14px;
    border: 1px solid #ccc; /* Border for definition */
    border-radius: 8px; /* Slight rounded edges */
    transition: border-color 0.2s, box-shadow 0.2s; /* Smooth transitions */
}

.inputField:focus, .textArea:focus {
    border-color: #007AFF; /* Apple blue */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Soft focus shadow */
}

.textArea {
    resize: vertical; /* Allows vertical resizing */
    min-height: 120px;
}

.submitButton {
    background-color: #007AFF; /* Apple blue */
    color: #ffffff;
    padding: 10px 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 14px;
    transition: background-color 0.2s;
}

.submitButton:hover {
    background-color: #005BB5; /* Darker Apple blue for hover */
}

.backButton {
    margin-top: 20px;
    font-size: 14px;
    background-color: transparent; /* Sleek transparent background */
    color: #007AFF; /* Apple blue as text color */
    padding: 10px 18px;
    border: 2px solid #007AFF; /* Solid border with the Apple blue */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.25s, color 0.25s; /* Smooth transitions for background and color */
}

.backButton:hover {
    background-color: #007AFF; /* On hover, Apple blue becomes the background */
    color: #ffffff; /* Text color turns white on hover */
    border-color: #005BB5; /* A darker blue for the border */
}
