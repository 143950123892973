/* For react-bootstrap, it might use .modal.show so let's increase specificity */
.activity-modal.modal.show {
    z-index: 1050;
}

.activity-modal .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 50px);
}

.activity-modal .modal-content {
    width: 100%;
    max-width: 1200px;
    overflow-y: auto;
}

.activity-modal-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
}

.my-certifications-container {
    font-family: 'Helvetica', sans-serif;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
}

.certifications-list {
    margin-top: 20px;
}

.certification-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.details-and-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.certification-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.certification-card h4 {
    margin-bottom: 20px;
}

.certification-card:hover {
    transform: translateY(-10px);
}

.certification-card-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: nowrap;
    gap: 5px; /* Reduced gap */
}

/* Specifically targeting button elements with classes */
button.add-cert-button,
button.view-tracking-button,
button.add-activity-button,
button.close-button {
    background-color: #007bfe !important; /* Use of !important for high specificity */
    border: none;
    padding: 10px 15px;
    border-radius: 20px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button.delete-button,
button.update-button {
    padding: 5px 9px;
    text-align: center;
    min-width: 50px;
    margin: 0 3px;
}


button.view-tracking-button {
    background-color: #12959e !important;
}

button.add-cert-button {
    background-color: rgba(13, 56, 211, 0.473) !important;
}

button.view-tracking-button:hover,
button.delete-button:hover,
button.add-cert-button:hover,
button.update-button:hover,
button.add-activity-button:hover,
button.close-button:hover {
    background-color: #0056b3 !important; /* Again, increased specificity */
}

button.delete-button {
    background-color: #dc3545 !important;
}

button.delete-button:hover {
    background-color: #c82333 !important;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.activity-card {
    background-color: #ffffff;
    padding: 5px 5px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 850px; /* Increase max-width */
}

.activity-card-buttons {
    display: flex;
    justify-content: space-between; /* distributes buttons across the container */
    gap: 5px; /* provides spacing between buttons */
    flex-wrap: wrap; /* in case the container is too narrow, allows buttons to wrap to the next line */
}

.progress-container {
    display: flex;
    align-items: center;
}

.progress-svg {
    margin-left: 10px;
}

button.recommend-cert-button {
    background-color: rgb(217, 187, 17) !important;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
}

button.recommend-cert-button:hover {
    background-color: #0056b3 !important;
}

.recommendation-modal.modal.show {
    z-index: 1060;
}

.recommendation-modal .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 50px);
}

.recommendation-modal .modal-content {
    width: 100%;
    max-width: 600px;
    overflow-y: auto;
}

.back-to-home-btn {
    background-color: rgb(17, 72, 211) !important;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
}

button.renew-button {
    background-color: green !important;
    border: none;
    margin-right: 10px;
}

button.renew-button:hover {
    background-color: rgb(2, 90, 2) !important;
}

@media (max-width: 768px) {

    .activity-modal-body {
        grid-template-columns: repeat(2, 1fr); /* Two activities per row */
    }

    .certification-card-buttons,
    .activity-card-buttons {
        gap: 10px; /* Increased gap for better spacing on mobile */
        justify-content: center; /* Center the buttons */
        flex-wrap: wrap;
    }

    button.add-cert-button,
    button.view-tracking-button,
    button.add-activity-button,
    button.close-button,
    button.delete-button,
    button.update-button {
        padding: 8px 12px; /* Adjusted padding for buttons */
        margin: 3px; /* Some margin for better spacing */
    }

    .certification-card,
    .activity-card {
        padding: 15px; /* Slightly reduced padding for cards */
    }
}

/* For very small mobile devices */
@media (max-width: 480px) {

    .my-certifications-container {
        padding: 15px; /* Reduce container padding for very small devices */
    }

    .certification-card,
    .activity-card {
        padding: 10px; /* Further reduced padding for cards */
    }

    .activity-modal-body {
        gap: 10px; /* Reduce gap for very small devices */
    }
}

.sort-dropdown {
    margin-left: 20px;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    appearance: none; /* Remove default appearance */
    background-color: #f4f4f4;
    outline: none;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out;
}

.sort-dropdown:hover {
    border-color: #007bff;
}

.full-height-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.search-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.certification-search-bar {
    width: 60%;
    padding: 10px 20px;
    border-radius: 25px;
    border: 2px solid lightgray;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;

    /* This adds a small icon on the left inside the input. You can remove this if you don't want an icon. */
    background-position: 5px 50%;
    background-repeat: no-repeat;
    padding-left: 40px; /* This pushes the input text towards the right to make space for the icon */
}

.certification-search-bar:focus {
    border-color: blue; /* Change this to your preferred highlight color */
}

.certification-search-bar::placeholder {
    color: #aaa;
}
