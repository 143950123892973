/* Base styles for larger screens (e.g. laptop) */

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    overflow-x: hidden;
}


.copyright-container {
    margin: 10px 0; /* Adjusting the margin */
    font-size: 14px;
    color: #888;
    text-align: center; /* center align the text */
    width: 100%; /* full width to ensure it's centered */
}

.footer-links-container {
    display: flex;
    gap: 10px;
}

.share-and-coffee-container {
    display: flex;
    align-items: center;  /* vertically center the items */
    gap: 20px;
}

.share-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;  /* changed to start to keep it left aligned with social icons */
    gap: 10px;
}

.share-prompt {
    font-size: 14px;
    color: #888;
    text-align: center;
    width: 100%; /* Let it occupy full width */
}

.social-logos {
    display: flex;
    justify-content: center; /* Center the logos */
    gap: 10px;
}

.social-logo {
    width: 24px; /* adjust size as needed */
    height: 24px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.social-logo:hover {
    opacity: 0.7;
}

/* Mobile styles (for screens up to 768px width) */

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: flex-start; /* Align to the left */
    }

    .footer-links-container {
        flex-direction: column;
        align-items: flex-start; /* Align to the left */
        gap: 5px; /* Adjust the gap between stacked links */
    }

    .share-container {
        align-items: flex-start;
    }

    .share-and-coffee-container {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: flex-start; /* Align items to the left on mobile */
        gap: 10px; /* provide some spacing */
    }

    .copyright-container {
        margin: 10px 0; /* Give it some space from other elements */
        width: 100%; /* Let it occupy full width */
    }
}
