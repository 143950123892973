/* Global styles for AddActivity component */
.add-activity {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial;
    background-color: #f7f7f7; /* Light background */
    padding: 30px;
    border-radius: 12px;
    width: 80%;
    margin: 50px auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Heading style */
.add-activity h2 {
    color: #333;
    font-weight: 500;
    margin-bottom: 25px;
    text-align: center;
}

/* Form styles */
.add-activity form {
    display: flex;
    flex-direction: column;
}

.add-activity label {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
}

.add-activity input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 20px;
    width: 100%;
    outline: none;
    transition: border 0.2s;
}

.add-activity input:focus {
    border-color: #007AFF; /* Apple's primary blue color for focus */
}

/* Button styles */
.add-activity button {
    cursor: pointer;
    background-color: #007AFF; /* Apple's primary blue color */
    color: #fff;
    font-size: 18px;
    border: none;
    padding: 12px 20px;
    border-radius: 10px;
    margin-right: 10px;
    transition: background-color 0.2s;
}

.add-activity button:hover {
    background-color: #0056D6;
}

/* Action buttons container */
.add-activity .action-buttons {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin: 20px auto 0 auto;
}

/* Cancel button styles */
.add-activity .cancel-button {
    cursor: pointer;
    background-color: #ccc; /* Light gray */
    color: #333;
    font-size: 16px;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    transition: background-color 0.2s;
}

.add-activity .cancel-button:hover {
    background-color: #aaa; /* Slightly darker gray on hover */
}

.mandatory-note {
    color: #f44336; /* Red color for attention */
    font-size: 0.9em;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
}
