.add-certification-container {
    max-width: 700px;
    margin: 40px auto;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-certification-container p {
    color: #f44336; /* Red color for attention */
    font-size: 0.9em;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
}

.add-certification-container h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    font-weight: 500;
}

.add-certification-container label {
    color: #555;
    font-weight: 500;
}

.add-certification-container button {
    display: block;
    margin: 20px auto;
    background-color: #007BFF;  /* You can tweak this for a more Apple blue color */
    border: none;
    color: white;
    padding: 10px 30px;
    border-radius: 8px;
    transition: background-color 0.2s;
}

.add-certification-container button:hover {
    background-color: #0056b3;  /* A slightly darker shade for hover */
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 10px;  /* space between buttons */
}
