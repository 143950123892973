.about-us {
    padding: 5rem 10%;
    background-color: #fff;
    min-height: 100vh;
    color: #555;
    font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
}

.about-us h1 {
    font-size: 3em;
    color: #333;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-bottom: 1rem;
    line-height: 1.2;
}

.about-us h2 {
    font-size: 2em;
    color: #444;
    margin-bottom: 2rem;
    font-weight: 600;
}

.about-us h3 {
    font-size: 1.8em;
    color: #666;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 500;
}

.about-us p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 3rem;
}

.return-home {
    background-color: #000;
    color: #fff;
    padding: 0.5rem 2rem;
    text-decoration: none;
    border-radius: 25px;
    font-weight: 500;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    font-size: 1em;
}

.return-home:hover {
    transform: translateY(-2px);
    background-color: #444;
}
