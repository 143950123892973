.update-container {
    padding: 50px 0;
    background-color: #f7f7f7;
    font-family: 'SF Pro Display', 'Helvetica Neue', Arial, sans-serif;
}

.mandatory-note {
    color: #f44336; /* Red color for attention */
    font-size: 0.9em;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
}

.update-title {
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
}

Form {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

Form.Label {
    font-weight: 500;
    color: #666;
}

Form.Control {
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    padding: 10px 15px;
    font-size: 16px;
    transition: border 0.2s;

    &:focus {
        border: 1px solid #007BFF;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
}

Form.Group {
    margin-bottom: 20px;
}

.update-btn, .cancel-btn {
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    transition: background-color 0.2s;
}

.update-btn {
    background-color: #007BFF;
    color: #fff;

    &:hover,
    &:focus {
        background-color: #0056b3;
    }
}

.cancel-btn {
    background-color: #DC3545;
    color: #fff;

    &:hover,
    &:focus {
        background-color: #b02a37;
    }
}
