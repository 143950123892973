.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
  }

  .login-box {
    width: 320px;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-input {
    width: 90%;
    padding: 12px 15px;
    margin: 8px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
  }

  .login-btn, .signup-btn, .forgot-password {
    display: block;
    width: 100%;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 8px;
  }

  .login-btn {
    background-color: #000;
    color: #fff;
  }

  .signup-btn {
    background-color: transparent;
    color: #888;
    border: 1px solid #888;
  }

  .forgot-password {
    background-color: transparent;
    color: #555;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }

  .login-error {
    width: 90%;
    margin: 8px 0;
    color: #e74c3c; /* This is a reddish color for the error */
    background-color: #fbeaea; /* Light red background */
    border: 1px solid #e74c3c;
    border-radius: 5px;
    padding: 8px 12px;
    text-align: center;
    font-size: 15px;
  }
