.activity-update-container {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 40px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.activity-update-container > h2 {
    margin: 20px 0; /* Adjust as needed */
}

h2 {
    color: #555;
    margin-bottom: 25px;
}

.input-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

input, textarea {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.update-button {
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #007AFF;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.update-button:hover {
    background-color: #005BD5;
}

.button-container {
    display: flex;
    justify-content: start;
    gap: 20px;
}

.cancel-button {
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #DC3545; /* a red color for cancel */
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 15px; /* space between update and cancel button */
}

.cancel-button:hover {
    background-color: #b02a37; /* a darker shade of red for hover */
}

.mandatory-note {
    color: #f44336; /* Red color for attention */
    font-size: 0.9em;
    text-align: center;
    margin-top: -20px;
    margin-bottom: 20px;
}
