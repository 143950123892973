/* Block: FourOFourPage */
.FourOFourPage {
    height: 100%;
    margin: 0;
    font-family: 'San Francisco', sans-serif;
    display: grid;
    justify-items: center;
    align-items: center;
    background: #fafafa;
}

/* Element: container */
.FourOFourPage__container {
    width: 100%;
    max-width: 500px;
    text-align: center;
    background: #fff;
    padding: 2em;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Element: title */
.FourOFourPage__title {
    font-size: 5em;
    margin: 0;
    color: #333;
}

/* Element: message */
.FourOFourPage__message {
    color: #777;
}

/* Element: homeLink */
.FourOFourPage__homeLink {
    display: inline-block;
    margin-top: 1em;
    padding: 0.5em 1em;
    border-radius: 4px;
    background: #007AFF;
    color: white;
    text-decoration: none;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.FourOFourPage__homeLink:hover {
    background: #005AD5;
}
