.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
}

.auth-box {
    width: 90%;           /* Adjust the width to take up 90% of its parent container */
    max-width: 600px;     /* Set a maximum width so it doesn't become too large on wider screens */
    padding: 40px 60px;   /* Increase the padding to maintain some space on the sides */
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h2 {
    font-weight: 500;
    margin-bottom: 10px;
}

p {
    font-size: 14px;
    margin-bottom: 30px;
    color: #888;
}

.input-group {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
}

label {
    font-size: 12px;
    margin-bottom: 5px;
    color: #555;
}

.auth-input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.auth-input:focus {
    border-color: #007AFF;
}

.auth-btn {
    display: block;
    width: 100%;
    padding: 12px 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 8px;
    background-color: #000;
    color: #fff;
    transition: background-color 0.2s ease-in-out;
}

.auth-btn:hover {
    background-color: #555;
}

.alert {
    width: 100%;
    text-align: center;
    padding: 10px;
    border-radius: 15px;
    margin: 10px 0;
    font-size: 14px;
}

.alert-danger {
    background-color: #FFD1D1;
    color: #E00000;
}

.alert-success {
    background-color: #D1FFD8;
    color: #009400;
}

.terms-container {
    width: 100%;
    margin: 10px 0;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 4px;
}

.checkbox-container input[type="checkbox"] {
    margin-right: 4px;
}

.terms-scroll {
    max-height: 150px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    padding: 8px;
    margin-bottom: 12px;
    border-radius: 5px;
}

.auth-btn[disabled] {
    background-color: #ddd;
    cursor: not-allowed;
}

.sign-in-link {
    margin-top: 20px;
    font-size: 14px;
    color: #888;
}

.sign-in-link a {
    color: #007AFF;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}

.sign-in-link a:hover {
    color: #0050c4;
}
